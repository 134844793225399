$white: #fff;
$translucent: rgba(255, 255, 255, 0.5);
$green: #09606b;
$blue: #096099;

.container {
  max-width: 50rem;
}

a {
  color: $green;
}

article.real a, .recommendation-content a, .podcast-content a, .talks-content a {
  text-decoration-line: underline;
}

.sidebar {
  background-color: $green;
}

.sidebar a:focus {
  color: $translucent !important;
}

.sidebar a:hover {
  color: $translucent !important;
}

a.badge-category {
  color: $blue !important;
  background-color: $white !important;
  text-decoration-line: none !important;
}

a.badge-category:hover {
  color: $white !important;
  background-color: $blue !important;
  text-decoration-line: none !important;
}

a.badge-category:focus {
  color: $white !important;
  background-color: $blue !important;
  text-decoration-line: none !important;
}

a.badge-tag {
  color: $white !important;
  background-color: $green !important;
  text-decoration-line: none !important;
}

a.badge-tag:hover {
  color: $green !important;
  background-color: $white !important;
  text-decoration-line: none !important;
}

a.badge-tag:focus {
  color: $green !important;
  background-color: $white !important;
  text-decoration-line: none !important;
}

.toc-wrapper {
  background: $green !important;
  color: $white !important;
  text-decoration-line: none !important;

  font-size: .8rem !important;
  position: fixed !important;
  top: 2rem !important;
  right: 1rem !important;
  max-height: 100% !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  display: flex !important;
  flex-flow: column !important;
  border-radius: 5px !important;
  z-index: 1000 !important;
}

a.toc-link {
  color: $white !important;
  text-decoration-line: none !important;
}

.toc-wrapper label, input#tocToggle:checked + label {
  background: none !important;
  background-image: none !important;
}

@media only screen and (min-width: 1800px) {
  input#tocToggle~#toc-toggle-cover, input#tocToggle:checked~#toc-toggle-cover, input#tocToggle:not(:checked)~#toc-toggle-cover {
      width: 100%;
      height: 3.5em;
      background: transparent;
      position: absolute;
      z-index: 1100;
      top: -1em;
      left: 0;
      display: block;
  }

  input#tocToggle~#TableOfContents, input#tocToggle:checked~#TableOfContents, input#tocToggle:not(:checked)~#TableOfContents {
    height: 100%;
    max-height: 100% !important;
    display: block;
  }

  input#tocToggle+label span#toc-title, input#tocToggle:checked+label span#toc-title, input#tocToggle:not(:checked)+label span#toc-title {
    display: inline-block;
    margin-left: 1em;
  }

  input#tocToggle+label, input#tocToggle:checked+label, input#tocToggle:not(:checked)+label {
    border-radius: 5px;
    font-weight: bold;
    min-width: 3em;
  }
}

@media only screen and (max-width: 1799px) {
  .toc-wrapper {
		box-shadow: #000 0 8px 24px 0px;
	}

  input#tocToggle:checked+label {
    min-height: 1em;
  }

  input#tocToggle + label {
    font-weight: bold;
    min-width: 2.6em;
    border-radius: 5px;
  }

  input#tocToggle:not(:checked)+label span#toc-title {
    display: initial;
    margin-left: 1em;
  }

  input#tocToggle~#toc-toggle-cover {
    display: none;
  }

  input#tocToggle:checked~#TableOfContents {
    display: none;
  }

  input#tocToggle:checked+label span#toc-title {
    display: none;
  }
}

.toc {
  background: $green !important;
  color: $white !important;
}

a.toc {
  color: $white !important;
  text-decoration-line: none !important;
}

@media only print {
  .toc-wrapper {
    display: none !important;
  }
}

ul.posts li {
  background: url('../img/list-bar.gif') no-repeat left top !important;
  list-style: none !important;
  padding: 0px 3px 0px 10px !important;
  margin: 0 !important;
}

ul.posts li:hover {
  background: url('../img/list-bar-active.gif') no-repeat left top !important;
  padding: 0px 3px 0px 13px !important;
  list-style: none !important;
  margin: 0 !important;
}

ul.posts li:active {
  background: url('../img/list-bar-active.gif') no-repeat left top !important;
  padding: 0px 3px 0px 13px !important;
  list-style: none !important;
  margin: 0 !important;
}

a.navigation-next {
  color: $green !important;
  border: 2px solid $green !important;
}

a.navigation-prev {
  color: $green !important;
  border: 2px solid $green !important;
}

.pagination-container {
  text-align: center;
}

ul.pagination li.page-item.active a {
  color: $green !important;
  border-bottom: 3px solid $green !important;
}

figcaption {
  padding: 0px !important;
  background-color: $white !important;
  text-align: center !important;
  margin-top: -15px;
}

.sidebar-nav {
  text-align: left !important;
}
